<template>
  <div>
    <!--    放映员点播编辑页面-->

    <!--    {{playId}}-->
    <!--    二。表单部分-->
    <!--    1.日期-->
    <div>
      <van-cell-group inset>
        <van-cell title="日期" required :label="selectPlayStartDate">
          <template #title>
            <b>日期</b>
          </template>
        </van-cell>
        <!--        <van-cell :value="selectPlayStartDate" label="selectPlayStartDate"  icon="calendar-o" />-->
      </van-cell-group>
    </div>
    <!--    2.时间-->
    <div>
      <van-cell-group inset>
        <van-cell title="" required>
          <template #title>
            <b>时间</b>
          </template>
        </van-cell>
        <van-cell
          :value="selectPlayStartTime"
          readonly
          icon="clock-o"
        ></van-cell>
      </van-cell-group>
    </div>
    <!--    3.放映地址选择-->
    <div>
      <van-cell-group inset>
        <van-cell required>
          <template #title>
            <b>放映地址</b>
          </template>
        </van-cell>
        <van-cell placeholder="暂无地址" readonly>
          <template #right-icon>
            <van-icon name="aim" />
          </template>
          <template #title v-if="selectPlayPlace.length > 0">
            <b>{{ selectPlayPlace }}</b>
          </template>
          <template #title v-else>
            <i style="color: gray">暂无地址</i>
          </template>
        </van-cell>
      </van-cell-group>
      <!--      地址：{{selectPlayPlace}}-->
    </div>
    <!--    3.影片选择-->
    <div>
      <!--     {{selectMovie}}-->
      <van-cell-group inset>
        <van-cell required>
          <template #title>
            <b>影片</b>
          </template>
        </van-cell>
        <van-cell v-for="(film, index) in selectMovie" :key="index">
          <template #title>
            {{ film.filmName }}
          </template>
        </van-cell>
        <van-cell v-if="selectMovie.length === 0" readonly>
          <template #title>
            <i style="color: gray">暂无影片</i>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@/api/index'

export default {
  name: 'edit',
  components: {},
  data() {
    return {
      activeName: 'a',

      playId: this.getUrlParam('id'),
      // 选中的影片
      selectMovie: [],
      // 日期
      selectPlayStartDate: '',
      // 时间
      selectPlayStartTime: '',
      // 放映地点
      selectPlayPlace: '',

      // 推荐电影
      hopeFilm: [],
      // 推荐类型影片
      hopeTypeFilm: [],
    }
  },
  mounted() {
    // 初始化
    this.initPage()
    // 获取已选择影片
  },
  methods: {
    // 初始化页面加载时数据
    /*
     * 初始化页面加载
     * */
    initPage() {
      this.playId = this.getUrlParam('id') //获取参数id
      this.selectPlayPlace = this.getUrlParam('playPlace') //获取参数selectPlayPlace
      this.selectPlayStartDate = this.getUrlParam('playStartDate') //获取当前日期
      this.selectPlayStartTime = this.getUrlParam('playStartTime').substring(
        0,
        this.getUrlParam('playStartTime').lastIndexOf(':'),
      ) //获取当前时间
      this.getMovieList(this.playId) //获取当日计划影片列表
    },

    // 获取当前日期计划影片
    getMovieList(id) {
      api.getFilmListByDateID(id).then((res) => {
        this.selectMovie = res.map((item, index) => {
          return {
            filmName: item.filmName,
            id: item.filmId,
            sort: index,
          }
        })
      })
    },
    // 选择放映地点
    onFinishAdress(selectedOptions) {
      this.selectPlayPlace = selectedOptions.map((p) => p.text).join('')
      this.showAddress = false
    },
    // 获取URL参数
    getUrlParam(name) {
      return this.$route.query[name] || ''
    },
  },
}
</script>

<style scoped lang="less">
.box {
  //width: 347px;
  min-height: 114px;
  background: rgba(255, 255, 255, 0.47);
  border-radius: @border-radius-base;
  border: 1px solid #428ffc;
  margin: 5px 2%;
}

.film {
  &-item {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #244e8a;

    h3 {
      margin: 0;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    div {
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #244e8a;
      line-height: 18px;
      //margin-top: 6px;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      align-content: space-between;
    }
    > div:first-child {
      width: 10%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-groupNum {
      color: gray !important;
      span {
        height: 21px;
        font-size: 16px;
        font-family: Helvetica;
        color: #ff843a;
        line-height: 19px;
        -webkit-background-clip: text;
      }
    }
  }
}

.mark1 {
  &:after {
    display: inline-block;
    content: 'q点播';
    padding: 5px;
    position: absolute;
    right: 2.3%;
    background: linear-gradient(121deg, #ff5c5c 0%, #ffa77c 100%);
    border-radius: 100px 0 0 100px;
    transform: translateY(-20px);
    color: white;
    width: 90px;
    text-align: center;
  }
}

.mark2 {
  &:after {
    display: inline-block;
    content: '集体点播';
    padding: 5px;
    position: absolute;
    right: 2.3%;
    background: linear-gradient(119deg, #828bff 0%, #dea9ff 100%);
    border-radius: 100px 0 0 100px;
    transform: translateY(-20px);
    color: white;
    width: 90px;
    text-align: center;
  }
}

.activeBox {
  background: linear-gradient(
    133deg,
    rgba(76, 129, 255, 0.2) 0%,
    rgba(171, 221, 255, 0.2) 100%
  );
}
</style>
